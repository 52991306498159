import { State as HomeState } from '~/store/home'
import { State as CompanyState, useCompanyStore, Investor } from '~/store/company'
import { State as NewsState } from '~/store/news'
import { State as PortfolioState } from '~/store/portfolio'
import { State as DocumentsState } from '~/store/documents'
import { State as InvestmentsState, useInvestmentsStore } from '~/store/investments';
import { State as ProductsState, useProductsStore } from '~/store/products'
import { useDocumentsStore } from '~/store/documents'
import { Client, useClientHandle } from '@urql/vue'
import { AirfundRepositoryAdapter } from './adapters/repository/airfund/adapter'
import { FundItem, LeadInputs, NoticeDocument, Notification } from '~/types/domain/domain'
import { CashEventRowsProcess, Options } from '~/generated/operations-airdistrib'

export const currentCompany = () => {
  const config = useRuntimeConfig()
  return config.public.COMPANY.toLowerCase()
}

export const getHomeData = async (client: Client | null = null): Promise<Partial<{ keyNumbers: HomeState['keyNumbers'], news: HomeState['news'], notifications: HomeState['notifications'] }>> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    return {
      news: (await queryContent(`/${company}/home/news`).findOne()).news,
      // chart: (await queryContent(`/${company}/home/chart`).findOne()).chart,
      keyNumbers: (await queryContent(`/${company}/home/key-numbers`).findOne()).keyNumbers,
      notifications: (await queryContent(`/${company}/home/notifications`).findOne()).notifications
    }
  } else {
    if (!client) {
      const handle = useClientHandle()
      client = handle.client
    }
    const companyStore = useCompanyStore()
    const investor = companyStore.selectedInvestor

    return AirfundRepositoryAdapter.getHomeData({ client, investorIds: [investor.id] })
  }
}

export const getCompanyData = async (client: Client = null): Promise<Omit<CompanyState, 'settings'>> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    return (await queryContent(`/${company}/info`).findOne()).info
  } else {
    if (!client) {
      const handle = useClientHandle()
      client = handle.client
    }
    return AirfundRepositoryAdapter.getCompanyData({ client })
  }
}

export const getContactsData = async ({ client = null }: { client?: Client }): Promise<CompanyState['contacts']> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    return (await queryContent(`/${company}/info`).findOne()).info.contacts
  } else {
    if (!client) {
      const handle = useClientHandle()
      client = handle.client
    }
    const investmentsStore = useInvestmentsStore()
    const shareIds = investmentsStore.getSharesList.map(share => share.id)

    return AirfundRepositoryAdapter.getContactsData({ client, shareIds })
  }
}

export const getArticleData = async ({ client = null, articleId }: { client?: Client, articleId: string }): Promise<NewsState['news'][0]> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    const news = (await queryContent(`/${company}/news`).findOne()).news
    return news.list.find((article) => article.id === articleId)
  } else {
    if (!client) {
      const handle = useClientHandle()
      client = handle.client
    }
    return AirfundRepositoryAdapter.getArticleData({ client, articleId })
  }
}

export const getNewsData = async (client: Client = null): Promise<{ news: NewsState['news'], categories: NewsState['categories'] }> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    const mock = (await queryContent(`/${company}/news`).findOne()).news
    return {
      news: mock.list,
      categories: mock.categories
    }
  } else {
    if (!client) {
      const handle = useClientHandle()
      client = handle.client
    }
    const pageSize = null
    const tagIds = []
    return AirfundRepositoryAdapter.getNewsData({ client, tagIds, pageSize })
  }
}

export const getCategoriesData = async (client: Client = null): Promise<InvestmentsState['categories']> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    return (await queryContent(`/${company}/funds-categories`).findOne()).categories
  } else {
    if (!client) {
      const handle = useClientHandle()
      client = handle.client
    }
    const companyStore = useCompanyStore()
    const investor = companyStore.selectedInvestor

    return AirfundRepositoryAdapter.getCategoriesData({ client, investorIds: [investor.id] })
  }
}

export const getInvestmentsData = async ({ client = null, categoryCode }: { client?: Client, categoryCode: string }): Promise<{ investmentsByVintageYear: PortfolioState['investmentsByVintageYear'], keyNumbers: PortfolioState['keyNumbers'], categories: InvestmentsState['categories'] }> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    const catToLower = categoryCode.toLowerCase()
    return {
      keyNumbers: (await queryContent(`/${company}/categories/${catToLower}/key-numbers`).findOne()).keyNumbers,
      investmentsByVintageYear: (await queryContent(`/${company}/categories/${catToLower}/investments-by-vintage-year`).findOne()).investmentsByVintageYear,
      categories: (await queryContent(`/${company}/funds-categories`).findOne()).categories
    }
  } else {
    if (!client) {
      const handle = useClientHandle()
      client = handle.client
    }
    const investmentsStore = useInvestmentsStore()
    const companyStore = useCompanyStore()
    const shareIds = []
    let funds = investmentsStore.getFundsList

    if (categoryCode === 'ALL') {
      shareIds.push(...investmentsStore.getSharesList.map(share => share.id))
    } else {
      switch (companyStore.settings.FUNDS_CATEGORIZATION_METHOD) {
        case 'CURRENCY':
          const shares = investmentsStore.getSharesList.filter(share => share.currency === categoryCode)
          shareIds.push(...shares.map(share => share.id))
          break
        case 'ASSET_CLASS':
          funds = funds.filter(fund => fund.assetClass === categoryCode)
          funds.forEach(fund => {
            shareIds.push(...fund.shares.map(share => share.id))
          })
          break
        default:
          funds = funds.filter(fund => fund.category === categoryCode)
          funds.forEach(fund => {
            shareIds.push(...fund.shares.map(share => share.id))
          })
          break
      }
    }

    const categories = investmentsStore.categories
    const investor = companyStore.selectedInvestor

    return AirfundRepositoryAdapter.getInvestmentsData({ client, shareIds, investorId: investor.id, categories })
  }
}

export const getTransactionsData = async ({ client }: { client: Client }): Promise<InvestmentsState['categories']> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    return (await queryContent(`/${company}/funds-categories`).findOne()).categories
  } else {
    const investmentsStore = useInvestmentsStore()
    const companyStore = useCompanyStore()

    const categories = investmentsStore.categories
    const investor = companyStore.selectedInvestor
    return AirfundRepositoryAdapter.getTransactionsData({ client, investorId: investor.id, categories })
  }
}

// TODO: connecter le repository aux vraies APIs
export const getPositionsData = async (categoryCode = 'ALL') => {
  const company = currentCompany()
  const catToLower = categoryCode.toLowerCase()
  return (await queryContent(`/${company}/categories/${catToLower}/positions`).findOne()).positions
}

export const getUserDocumentsData = async (client: Client | null = null): Promise<Partial<{ user: DocumentsState['user'] }>> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    return (await queryContent(`/${company}/documents`).findOne()).documents
  } else {
    if (!client) {
      const handle = useClientHandle()
      client = handle.client
    }
    const companyStore = useCompanyStore()
    const investor = companyStore.selectedInvestor

    return AirfundRepositoryAdapter.getUserDocumentsData({ client, investorId: investor.id })
  }
}

export const getInvestmentsDocumentsData = async ({ client }: { client: Client }): Promise<DocumentsState['investments']['documents']> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    return (await queryContent(`/${company}/documents`).findOne()).documents.investments.documents
  } else {
    const investmentsStore = useInvestmentsStore()
    const companyStore = useCompanyStore()

    const investor = companyStore.selectedInvestor
    const funds = investmentsStore.getFundsList
    return AirfundRepositoryAdapter.getInvestmentsDocumentsData({ client, investorId: investor.id, funds })
  }
}

export const getNoticesDocumentsData = async ({ client }: { client: Client }): Promise<DocumentsState['notices']['documents']> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    return (await queryContent(`/${company}/documents`).findOne())?.documents?.notices?.documents ?? []
  } else {
    const investmentsStore = useInvestmentsStore()
    const companyStore = useCompanyStore()

    const investor = companyStore.selectedInvestor
    const funds = investmentsStore.getFundsList
    // NOTE: use this when we succeed to get suscriptions working
    // console.log(await AirfundRepositoryAdapter.getNoticesDocumentsData({ client, investorId: investor.id, funds, companyId: companyStore.id }))
    return AirfundRepositoryAdapter.getNoticesDocumentsData({ client, investorId: investor.id, funds })
  }
}

export const updateUserDocument = async ({ client, file, documentId }: { client: Client, file: File, documentId?: string }): Promise<Partial<{ user: DocumentsState['user'] }>> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    return (await queryContent(`/${company}/documents`).findOne()).documents.user
  } else {
    const documentsStore = useDocumentsStore()
    const document = documentsStore.user.documents.find(doc => doc.id === documentId)

    return AirfundRepositoryAdapter.updateUserDocument({ client, file, document })
  }
}

export const updateTaskStatus = async ({ client, notificationId }: { client: Client, notificationId: string }): Promise<boolean> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    return Promise.resolve(true)
  } else {
    return AirfundRepositoryAdapter.updateTaskStatus({ client, notificationId })
  }
}

export const archiveNotification = async ({ client, notificationId }: { client: Client, notificationId: string }): Promise<Notification | null> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    return Promise.resolve(null)
  } else {
    return AirfundRepositoryAdapter.archiveNotification({ client, notificationId })
  }
}

export const updateProfilePicture = async ({ client, file }: { client: Client, file: File }): Promise<Partial<string>> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    return (await queryContent(`/${company}/info`).findOne()).info.user.imageUrl
  } else {
    return AirfundRepositoryAdapter.updateProfilePicture({ client, file })
  }
}

export const getProductsData = async ( client: Client ): Promise<Pick<ProductsState, 'products' | 'filters'>> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    const productsData = (await queryContent(`/${company}/products-list`).findOne())
    return {
      products: productsData.products,
      filters: productsData.filters
    }
  } else {
    const productsStore = useProductsStore()
    const company = useCompanyStore()

    return AirfundRepositoryAdapter.getProductsData({ client, filters: productsStore.selectedFilters, company })
  }
}

export const getProductData = async ({ client = null, productId }: { client?: Client, productId: string }): Promise<ProductsState['products'][0]> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    const products = (await queryContent(`/${company}/products-list`).findOne()).products
    const product = products.find(p => p.id === productId)
    return product
  } else {
    if (!client) {
      const handle = useClientHandle()
      client = handle.client
    }
    return AirfundRepositoryAdapter.getProductData({ client, productId })
  }
}

export const createLead = async ({ client = null, formInputs, fundId }: { client?: Client, formInputs: LeadInputs, fundId: string }): Promise<boolean> => {
  if (!client) {
    const handle = useClientHandle()
    client = handle.client
  }
  const companyStore = useCompanyStore()
  return AirfundRepositoryAdapter.createLead({ client, formInputs, companyId: companyStore.id, fundId })
}

export const getSettingsData = async (client: Client = null): Promise<CompanyState['settings']> => {
  if (!client) {
    const handle = useClientHandle()
    client = handle.client
  }
  const companyStore = useCompanyStore()
  return AirfundRepositoryAdapter.getSettingsData({ client, companyId: companyStore.id })
}

export const getSelectedInvestor = async (
  { client = null, selectedInvestorId }:
  { client: Client | null, selectedInvestorId: string }
): Promise<Investor | null> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    return (await queryContent(`/${company}/info`).findOne()).info.user.investors[0]
  } else {
    if (!client) {
      const handle = useClientHandle()
      client = handle.client
    }

    return AirfundRepositoryAdapter.getSelectedInvestor({ client, selectedInvestorId })
  }
}

export const getInvestors = async (
  { client = null, search = '', options = { limit: 10 }, selectedInvestorId = '' }:
  { client: Client | null, search?: string, options?: Options, selectedInvestorId?: string }
): Promise<Investor[]> => {
  const config = useRuntimeConfig()
  if (config.public.MOCKED) {
    const company = currentCompany()
    return (await queryContent(`/${company}/info`).findOne()).info.user.investors
  } else {
    if (!client) {
      const handle = useClientHandle()
      client = handle.client
    }

    return AirfundRepositoryAdapter.getInvestors({ client, search, options, selectedInvestorId })
  }
}

export const callReadToNotice = async ({ client, noticeId, funds }: { client: Client, noticeId: string, funds: FundItem[] }): Promise<NoticeDocument | null> => {
  const config = useRuntimeConfig()
  let notice: NoticeDocument | null = null;
  if (config.public.MOCKED) {
    notice = await Promise.resolve(null) // TODO: mock
  } else {
    notice = await AirfundRepositoryAdapter.callReadToNotice({ client, noticeId, funds })
  }
  return notice
}
export const useNotificationsSubscription = (client: Client) => {
  const notifications = ref([]);
  const error = ref(null);

  const handleData = (newData) => {
    notifications.value = newData.notifications;
  };

  const handleError = (subscriptionError) => {
    error.value = subscriptionError;
  };

  const unsubscribe = AirfundRepositoryAdapter.useNotificationsSubscription(client, handleData, handleError);

  return {
    notifications,
    error,
    unsubscribe,
  };
};

export default {
  getHomeData,
  getCategoriesData,
  getTransactionsData,
  getCompanyData,
  getContactsData,
  getArticleData,
  getNewsData,
  getInvestmentsData,
  getPositionsData,
  getUserDocumentsData,
  getInvestmentsDocumentsData,
  getNoticesDocumentsData,
  updateUserDocument,
  updateTaskStatus,
  updateProfilePicture,
  archiveNotification,
  callReadToNotice,
  getProductsData,
  getProductData,
  createLead,
  getSettingsData,
  getSelectedInvestor,
  getInvestors,
  currentCompany,
  useNotificationsSubscription,
}
