import { CashEventRowType, CountImportedCashEventRowsDocument, CountImportedCashEventRowsQuery, CountImportedCashEventRowsQueryVariables, GetEqualizationCashEventRowsDocument, GetEqualizationCashEventRowsQuery, GetEqualizationCashEventRowsQueryVariables } from './../../../../generated/operations-airdistrib';
import { State as HomeState } from '~/store/home'
import { State as CompanyState, useCompanyStore, Investor } from '~/store/company'
import { State as NewsState } from '~/store/news'
import { State as PortfolioState } from '~/store/portfolio'
import { State as DocumentsState, UserDocument } from '~/store/documents'
import { State as InvestmentsState, useInvestmentsStore } from '~/store/investments'
import { State as ProductsState } from '~/store/products'
import { Client } from '@urql/vue'
import {
  GetCashEventWorkflowsByShareClassesQuery,
  GetCashEventWorkflowsByShareClassesQueryVariables,
  GetCashEventWorkflowsByShareClassesDocument,
  GetEngagedAndCalledByShareClassesDocument,
  GetEngagedAndCalledByShareClassesQuery,
  GetEngagedAndCalledByShareClassesQueryVariables,
  GetHomeKeyNumbersDocument,
  GetHomeKeyNumbersQuery,
  GetHomeKeyNumbersQueryVariables,
  GetInvestmentsDataDocument,
  GetInvestmentsDataQuery,
  GetInvestmentsDataQueryVariables,
  GetTransactionsDocument,
  GetTransactionsQuery,
  GetTransactionsQueryVariables,
  CashEventRowsProcess,
  GetClientRoleQuery,
  GetClientRoleQueryVariables,
  GetClientRoleDocument,
  GetTradesQuery,
  GetTradesQueryVariables,
  GetTradesDocument,
  GetAggregatedCashEventRowsQuery,
  GetAggregatedCashEventRowsQueryVariables,
  GetAggregatedCashEventRowsDocument,
  CashEventType,
  TradeType,
  GetCatchingUpCashEventRowsQuery,
  GetCatchingUpCashEventRowsQueryVariables,
  GetCatchingUpCashEventRowsDocument,
  ReceiveCashEventWorkflowMutation,
  ReceiveCashEventWorkflowMutationVariables,
  ReceiveCashEventWorkflowDocument,
  GetInvestorsQuery,
  GetInvestorsQueryVariables,
  GetInvestorsDocument,
  Options,
  SortType,
  FilterOperation,
  GetSelectedInvestorQuery,
  GetSelectedInvestorQueryVariables,
  GetSelectedInvestorDocument,
} from '~/generated/operations-airdistrib'
import {
  AssetClass,
  CreateLeadDocument,
  CreateLeadMutation,
  CreateLeadMutationVariables,
  GetCompanyFromAfDocument,
  GetCompanyFromAfQuery,
  GetContactsFromAfDocument,
  GetContactsFromAfQuery,
  GetContactsFromAfQueryVariables,
  GetDocumentsFromAfDocument,
  GetDocumentsFromAfQuery,
  GetDocumentsFromAfQueryVariables,
  GetIndicatorsFromAfDocument,
  GetIndicatorsFromAfQuery,
  GetIndicatorsFromAfQueryVariables,
  GetNotificationsFromAfDocument,
  GetNotificationsFromAfQuery,
  GetProductFromAfDocument,
  GetProductFromAfQuery,
  GetProductFromAfQueryVariables,
  GetProductsFromAfDocument,
  GetProductsFromAfQuery,
  GetProductsFromAfQueryVariables,
  GetRecommendedFundsFromAfDocument,
  GetRecommendedFundsFromAfQuery,
  GetRecommendedFundsFromAfQueryVariables,
  GetSharesFromAfDocument,
  GetSharesFromAfQuery,
  GetSharesFromAfQueryVariables,
  GetUserFromAfDocument,
  GetUserFromAfQuery,
  InvestmentStrategy,
  Sfdr,
  TaskStatus,
  UpdateTaskStatusDocument,
  UpdateTaskStatusMutation,
  UpdateTaskStatusMutationVariables,
  UpdateProfileDocument,
  UpdateProfileMutation,
  UpdateProfileMutationVariables,
  ShareClassFragment as ShareClass,
  SubscribeNotificationsDocument,
  ArchiveNotificationMutation,
  ArchiveNotificationMutationVariables,
  ArchiveNotificationDocument,
  GetInvestorPortalSettingsQuery,
  GetInvestorPortalSettingsQueryVariables,
  GetInvestorPortalSettingsDocument,
} from '~/generated/operations-airfund'
import {
  GetArticleFromAwDocument,
  GetArticleFromAwQuery,
  GetArticleFromAwQueryVariables,
  GetInvestorDocumentsFromAwDocument,
  GetInvestorDocumentsFromAwQuery,
  GetNewsFromAwDocument,
  GetNewsFromAwQuery,
  GetNewsFromAwQueryVariables,
  InvestorAddDocDocument,
  InvestorAddDocMutation,
  InvestorAddDocMutationVariables,
  InvestorRemoveDocDocument,
  InvestorRemoveDocMutation,
  InvestorRemoveDocMutationVariables} from '~/generated/operations-airwealth'
import {
  getPublicUrl as getPublicUrlFromS3,
  removeAirwealthDocument as removeAirwealthDocumentFromS3,
  S3EndpointCode,
  uploadAirfundImage as uploadAirfundImageToS3,
  uploadAirwealthDocument as uploadAirwealthDocumentToS3,
} from '~/utils/s3';
import { convertEpochDayToDate, DateFormat, getTodayInEpochDay } from '~/utils/dates'
import {
  afCallsToDomain,
  afDistributionsToDomain,
  afRedemptionsToDomain,
  categoryFundToFundItem,
  CustomContactItem,
  dpiToIndicator,
  fundCaracteristicsToDomain,
  getCategoriesFromFunds,
  getFundsFromShareClasses,
  investorDocumentsToDomain,
  multipleToIndicator,
  tasksToDomain,
  rvpiToIndicator,
  shareCaracteristicsToDomain,
  shareDocumentsToDomain,
  triToIndicator,
  tvpiToIndicator,
  rentalYieldToIndicator,
  occupancyRateToIndicator,
  userDocumentsToDomain,
  noticesDocumentsToDomain,
  notificationsToDomain,
  targetMultipleToIndicator,
  targetTriToIndicator,
  getForceTypeFromInvestors,
  investorAfToDomain
} from './fieldMappers'
import { i18n } from '~/utils/i18n'
import { Currency, formatCurrency, HumanizeOptions } from '~/utils/numbers'
import { FundItem, FundLayout, LeadInputs, NoticeDocument, ShareItem, Notification, DistributionItem, CallItem } from '~/types/domain/domain'
import { compareDesc } from 'date-fns'
export class AirfundRepositoryAdapter {
  public static async getHomeData ({ client, investorIds }: { client: Client, investorIds: string[] }): Promise<{ keyNumbers: HomeState['keyNumbers'], news: HomeState['news'], notifications: HomeState['notifications'] }> {
    const today = getTodayInEpochDay()
    const companyStore = useCompanyStore()

    const [homeKeyNumbersQuery, newsQuery] = await Promise.all([
      client.query<GetHomeKeyNumbersQuery, GetHomeKeyNumbersQueryVariables>(
        GetHomeKeyNumbersDocument,
        { startDate: today, endDate: today, investorIds },
        { clientName: 'airdistrib' }
      ).toPromise(),
      client.query<GetNewsFromAwQuery, GetNewsFromAwQueryVariables>(
        GetNewsFromAwDocument,
        { selectedKind: 'NewsContent', tagIds: [], pageSize: 2 },
        { clientName: 'airwealth' }
      ).toPromise()
    ])

    const keyNumbersData = homeKeyNumbersQuery?.data?.keyNumbers[0] ?? {}
    const keyNumbers = [
      {
        value: keyNumbersData?.engaged,
        code: 'investments'
      },
      {
        value: keyNumbersData?.called,
        code: 'amountCalled'
      },
      {
        value: keyNumbersData?.amountToCall,
        code: 'amountToCall'
      }
    ]

    if (companyStore.settings.ENABLE_HOME_VALORIZATION) {
      keyNumbers.push(
        {
          value: keyNumbersData?.valorization,
          code: 'valuation'
        }
      )
    }

    let tasksData: any = []
    let notificationsData: any = []

    if (!companyStore.user.impersonationMode) {
      const notificationsQuery = await client.query<GetNotificationsFromAfQuery>(
        GetNotificationsFromAfDocument,
        {},
        { clientName: 'airfund' }
      ).toPromise()

      tasksData = notificationsQuery?.data?.tasks ?? []
      notificationsData = notificationsQuery?.data?.notifications ?? []
    }

    const newsData = newsQuery?.data?.news ?? []
    const news = newsData.map(item => {
      return {
        id: item.id,
        title: item?.title,
        publicationDate: convertEpochDayToDate(item?.date as number),
        image: getPublicUrlFromS3((item?.image as string), S3EndpointCode.AIRWEALTH),
        tags: item.tags,
        authors: item.authors,
        preview: item?.preview,
        content: item?.content,
        averageReadTime: item?.averageReadTime
      }
    })

    return {
      keyNumbers: keyNumbers as HomeState['keyNumbers'],
      news: news as NewsState['news'],
      notifications: [...notificationsToDomain(notificationsData), ...tasksToDomain(tasksData)]
    }
  }

  public static async getCompanyData ({ client }: { client: Client }): Promise<Omit<CompanyState, 'settings'>> {
    const config = useRuntimeConfig()
    const companyStore = useCompanyStore()

    const userRequest = await client.query<GetUserFromAfQuery>(
      GetUserFromAfDocument,
      {},
      { clientName: 'airfund' }
    ).toPromise()

    const companyRequest = await client.query<GetCompanyFromAfQuery>(
      GetCompanyFromAfDocument,
      {
        id: config.public.CLIENT_ID && config.public.CLIENT_ID.length > 0 ? config.public.CLIENT_ID : userRequest?.data?.session?.user?.company?.id
      },
      { clientName: 'airfund' }
    ).toPromise()

    const company = companyRequest?.data?.company
    const userContact = userRequest.data?.session?.user?.contact

    let imageUrl
    if ((company?.logo as string).includes('http')) {
      imageUrl = company?.logo
    } else {
      imageUrl = getPublicUrlFromS3(company?.logo as string) || '/company-fallback.png'
    }
    const impersonationMode = userContact?.teams?.some(team => team?.tag?.teamAttributes?.onbehalfOfAllowed === true) || false

    return {
      name: company?.fundGroupName,
      url: company?.website || '#',
      id: company?.id,
      imageUrl,
      content: company?.shortDescription,
      user: {
        id: userContact?.id,
        name: `${userContact?.firstName} ${userContact?.lastName}`,
        email: userContact?.email,
        imageUrl: getPublicUrlFromS3(userContact?.picture as string) || '/profile-fallback.png',
        role: userContact?.title,
        investors: [],
        preferredLanguage: userRequest.data?.session?.user?.preferredLanguage || 'en',
        investorType: userContact?.targetInvestorType,
        nationality: userContact?.nationality,
        usPerson: userContact?.usPerson || null,
        impersonationMode,
        emailValidated: userRequest.data?.session?.emailValidated || false
      },
      selectedInvestor: companyStore.selectedInvestor,
      contacts: []
    } as Omit<CompanyState, 'settings'>
  }

  public static async getSelectedInvestor(
    { client, selectedInvestorId }:
    { client: Client, selectedInvestorId: string }
  ): Promise<Investor | null> {
    const companyStore = useCompanyStore()
    const impersonationMode = companyStore.user.impersonationMode

    const getSelectedInvestorQuery = await client.query<GetSelectedInvestorQuery, GetSelectedInvestorQueryVariables>(
      GetSelectedInvestorDocument,
      { selectedInvestorId },
      { clientName: 'airdistrib' }
    ).toPromise()

    const investor = getSelectedInvestorQuery.data?.investor
    if (!investor) {
      return null
    }

    const forceType = getForceTypeFromInvestors([investor], companyStore.settings.ENABLE_NUMBER_FORMATTING)
    return investorAfToDomain(investor, forceType, impersonationMode, companyStore.user.id)
  }

  public static async getInvestors(
    { client, search, options, selectedInvestorId }:
    { client: Client, search: string, options: Options, selectedInvestorId: string }
  ): Promise<Investor[]> {
    const companyStore = useCompanyStore()
    const userContactId = companyStore.user.id
    const impersonationMode = companyStore.user.impersonationMode

    const getInvestorsQuery = await client.query<GetInvestorsQuery, GetInvestorsQueryVariables>(
      GetInvestorsDocument,
      {
        search,
        options: {
          ...options,
          filters: [
            {
              field: '_id',
              op: FilterOperation.Ne,
              vString: selectedInvestorId || ''
            }
          ],
          sort: [
            {
              field: 'name',
              order: SortType.Asc
            }
          ]
        }
      },
      { clientName: 'airdistrib' }
    ).toPromise()

    const forceType = getForceTypeFromInvestors(getInvestorsQuery.data?.investors, companyStore.settings.ENABLE_NUMBER_FORMATTING)

    let investors = (getInvestorsQuery.data?.investors || []).map(obj => {
      return investorAfToDomain(obj, forceType, impersonationMode, userContactId)
    })

    return investors
  }

  public static async getContactsData ({ client, shareIds }: { client: Client, shareIds: string[] }): Promise<CompanyState['contacts']> {
    let funds: Array<GetContactsFromAfQuery['shareClasses'][0]['fund']> = []
    if (shareIds.length > 0) {
      const request = await client.query<GetContactsFromAfQuery, GetContactsFromAfQueryVariables>(
        GetContactsFromAfDocument,
        { ids: shareIds },
        { clientName: 'airfund' }
      ).toPromise()

      funds = request?.data?.shareClasses.map(shareClass => shareClass.fund) ?? []
    }

    const filteredFunds = funds.filter((fund, index, self) => self.findIndex(f => f?.id === fund?.id) === index)

    const contactsDict: Record<string, CustomContactItem> = {}
    filteredFunds.forEach(fund => {
      fund?.contacts.forEach(contact => {
        const categories = fund.categories.map(category => category.tag.name)
        if (!contactsDict[contact.id]) {
          contactsDict[contact.id] = {
            ...contact,
            categories
          } as CustomContactItem
        } else {
          contactsDict[contact.id].categories.push(...categories)
        }
      })
    })

    return Object.values(contactsDict).map(contact => {
      return {
        categories: contact.categories,
        name: `${contact.firstName} ${contact.lastName}`,
        phone: contact.workPhone,
        email: contact.email
      } as CompanyState['contacts'][number]
    })
  }

  public static async getArticleData ({ client, articleId }: { client: Client, articleId: string }): Promise<NewsState['news'][0]> {
    const articleQuery = await client.query<GetArticleFromAwQuery, GetArticleFromAwQueryVariables>(
      GetArticleFromAwDocument,
      { id: articleId },
      { clientName: 'airwealth' }
    ).toPromise();

    const articleData = articleQuery.data?.article ?? null

    if (articleData) {
      return {
        id: articleData.id,
        title: articleData?.title,
        publicationDate: convertEpochDayToDate((articleData?.date as number), DateFormat.DMMMMYYYY),
        image: getPublicUrlFromS3((articleData?.image as string), S3EndpointCode.AIRWEALTH),
        tags: articleData.tags.filter(tag => tag.label !== null),
        authors: articleData.authors,
        preview: articleData?.preview,
        content: articleData?.content,
        averageReadTime: articleData?.averageReadTime
      } as unknown as NewsState['news'][0]
    }
    return null as unknown as NewsState['news'][0]
  }

  public static async getNewsData ({ client, tagIds = [], pageSize = null }: { client: Client, tagIds: string[], pageSize?: number | null }): Promise<{ news: NewsState['news'], categories: NewsState['categories'] }> {
    const newsQuery = await client.query<GetNewsFromAwQuery, GetNewsFromAwQueryVariables>(
      GetNewsFromAwDocument,
      { selectedKind: 'NewsContent', tagIds, pageSize },
      { clientName: 'airwealth' }
    ).toPromise();

    const newsData = newsQuery?.data?.news ?? []
    const news = newsData.map(item => {
      return {
        id: item?.id,
        title: item?.title,
        publicationDate: convertEpochDayToDate(item?.date as number),
        image: getPublicUrlFromS3((item?.image as string), S3EndpointCode.AIRWEALTH),
        tags: item.tags,
        authors: item.authors,
        preview: item?.preview,
        content: item?.content,
        averageReadTime: item?.averageReadTime
      }
    })

    const categories: { code: string | null, label: string }[] = [
      {
        code: null,
        label: i18n.t('news.all_news')
      }
    ]
    let labels = news.map(item => {
      return item.tags.map(tag => tag.label)
    }).flat()
    labels = labels.filter(item => item)
    const ids = labels.map(item  => item?.id)
    labels = labels.filter((item, index) => !ids.includes(item?.id, index + 1)) // remove duplicates

    labels.forEach(item => {
      const category = {
        code: item?.id,
        label: item?.name
      }
      categories.push(category as { code: string, label: string })
    })

    return {
      news: news as NewsState['news'],
      categories: categories as NewsState['categories']
    }
  }

  public static async getInvestmentsData ({ client, shareIds = [], investorId, categories }: { client: Client, shareIds: string[], investorId: string, categories: InvestmentsState['categories'] }): Promise<{ investmentsByVintageYear: PortfolioState['investmentsByVintageYear'], keyNumbers: PortfolioState['keyNumbers'], categories: InvestmentsState['categories'] }> {
    const today = getTodayInEpochDay()

    let investmentsData, indicatorsData
    if (shareIds.length > 0) {
      [investmentsData, indicatorsData] = await Promise.all([
        client.query<GetInvestmentsDataQuery, GetInvestmentsDataQueryVariables>(
          GetInvestmentsDataDocument,
          { startDate: today, endDate: today, investorIds: [investorId], shareClassIds: shareIds },
          { clientName: 'airdistrib' }
        ).toPromise(),
        client.query<GetIndicatorsFromAfQuery, GetIndicatorsFromAfQueryVariables>(
          GetIndicatorsFromAfDocument,
          { investorId, shareClassIds: shareIds },
          { clientName: 'airfund' }
        ).toPromise()
      ])
    }

    const keyNumbers = investmentsData?.data?.keyNumbers?.[0] ?? {}
    let investmentsByVintageYear = investmentsData?.data?.investmentsByVintageYear ?? []
    const products: PortfolioState['investmentsByVintageYear']['products'] = []
    const dates: string[] = []
    const investmentsByVintageYearItems = {
      engaged: {
        data: [],
        code: 'engaged'
      },
      called: {
        data: [],
        code: 'called'
      },
      valorization: {
        data: [],
        code: 'valorization',
        stack: "TVPI"
      },
      distributed: {
        data: [],
        code: 'distributed',
        stack: "TVPI"
      }
    }

    investmentsByVintageYear = investmentsByVintageYear.sort((a, b) => {
      return a.shareClassAd?.fullShareClassName.localeCompare(b.shareClassAd?.fullShareClassName as string)
    })
    investmentsByVintageYear.forEach(investment => {
      const shareClass = investment?.shareClassAd
      if (shareClass) {
        const year = convertEpochDayToDate(shareClass.shareClassLaunchDate, DateFormat.YYYY) as string
        products.push({ name: shareClass.fullShareClassName, currency: shareClass.currency as Currency });
        dates.push(year);
        ['engaged', 'called', 'valorization', 'distributed'].forEach(key => {
          investmentsByVintageYearItems[key].data.push(investment[key] ?? 0)
        })
      }
    })

    const investor = indicatorsData?.data?.investor
    const shareClasses = indicatorsData?.data?.shareClasses ?? []

    categories.forEach(category => {
      category?.umbrellas?.forEach(umbrella => {
        umbrella.funds.forEach(fund => {
          fund.shares.forEach(share => {
            const indicators = investor?.indicators.filter((indicator) => {
              return indicator.relationKeys?.shareClass === share.id
            }) ?? []
            const shareClass = shareClasses.find((shareClass) => {
              return shareClass.id === share.id
            })
            if (shareClass) {
              indicators.push(...shareClass.indicators)
            }
            indicators.sort((a, b) => b.date - a.date)

            if (indicators.length > 0) {
              share.indicators.push(
                multipleToIndicator(indicators),
                triToIndicator(indicators),
                dpiToIndicator(indicators),
                rvpiToIndicator(indicators),
                tvpiToIndicator(indicators),
                rentalYieldToIndicator(indicators),
                occupancyRateToIndicator(indicators)
              )

              share.indicators = share.indicators.filter((share, index, self) => self.findIndex(s => s.code === share.code) === index)
            }
          })
        })
      })
    })

    return {
      investmentsByVintageYear: {
        products,
        dates,
        items: Object.values(investmentsByVintageYearItems),
      },
      keyNumbers: [
        {
          value: keyNumbers?.engaged,
          code: 'engaged'
        },
        {
          value: keyNumbers?.called,
          code: 'called'
        },
        {
          value: keyNumbers?.amountToCall,
          code: 'amountToCall'
        },
        {
          value: keyNumbers?.valorization,
          code: 'valuation'
        },
        {
          value: keyNumbers?.distributed,
          code: 'distributions'
        }
      ] as PortfolioState['keyNumbers'],
      categories
    }
  }

  public static async getCategoriesData ({ client, investorIds }: { client: Client, investorIds: string[] }): Promise<InvestmentsState['categories']> {
    const companyStore = useCompanyStore()
    const today = getTodayInEpochDay()

    const engagedSharesQuery = await client.query<GetEngagedAndCalledByShareClassesQuery, GetEngagedAndCalledByShareClassesQueryVariables>(
      GetEngagedAndCalledByShareClassesDocument,
      { startDate: today, endDate: today, investorIds },
      { clientName: 'airdistrib' }
    ).toPromise()

    const aggregatedPositions = engagedSharesQuery?.data?.aggregatedPositions ?? []
    const subscriptionTrades = engagedSharesQuery?.data?.subscriptionTrades ?? []

    const funds = getFundsFromShareClasses(aggregatedPositions)
    const categories = getCategoriesFromFunds(funds, companyStore.settings.FUNDS_CATEGORIZATION_METHOD)

    return categories.map(cat => {
      const categoryFunds = funds.filter(fund => {
        switch (companyStore.settings.FUNDS_CATEGORIZATION_METHOD) {
          case 'CURRENCY':
            if (cat.id !== null) {
              return fund.currency === cat.id
            }
            return fund.currency === null
          case 'ASSET_CLASS':
            if (cat.id !== null) {
              return fund.assetClass === cat.id
            }
            return fund.assetClass === null
          default:
            if (cat.id !== null) {
              const fundCategoriesIds = fund.categories.map(c => c.id)
              return fundCategoriesIds.includes(cat.id)
            }
            return fund.categories.length === 0
        }
      })

      let umbrellaDict: Record<string, any> = {}
      categoryFunds.forEach(fund => {
        const umbrella = fund?.umbrellaName || ''
        const formattedFund = categoryFundToFundItem({ fund, aggregatedPositions, subscriptionTrades, category: cat.id })

        if (formattedFund.shares.length > 0) {
          if (umbrellaDict[umbrella]) {
            umbrellaDict[umbrella].funds.push(formattedFund)
          } else {
            umbrellaDict[umbrella] = {
              code: umbrella,
              name: umbrella ? umbrella : 'EMPTY',
              funds: [formattedFund]
            }
          }
        }
      })
      let umbrellas = Object.values(umbrellaDict) as InvestmentsState['categories'][0]['umbrellas']

      umbrellas?.forEach(umbrella => {
        umbrella.funds = umbrella.funds.sort((a, b) => a.name.localeCompare(b.name))
      })
      umbrellas = umbrellas?.sort((a, b) => a.name.localeCompare(b.name))

      return {
        name: cat.name,
        code: cat.id,
        layout: FundLayout.PRIVATE_EQUITY,
        current: true,
        umbrellas: umbrellas?.filter(umb => umb.funds.length > 0)
      }
    }).filter(cat => (cat?.umbrellas?.length as number) > 0) as InvestmentsState['categories']
  }

  public static async getCashEventRowsProcess ({ client }: { client: Client }): Promise<CashEventRowsProcess> {
    const clientRoleQuery = await client.query<GetClientRoleQuery, GetClientRoleQueryVariables>(
      GetClientRoleDocument,
      {},
      { clientName: 'airdistrib' }
    ).toPromise()

    const cashEventRowsProcess = clientRoleQuery?.data?.clientRole?.cashEventsSettings?.cashEventRowsProcess

    return cashEventRowsProcess || CashEventRowsProcess.None
  }

  public static async getTransactionsData ({ client, investorId, categories }: { client: Client, investorId: string, categories: InvestmentsState['categories'] }): Promise<InvestmentsState['categories']> {
    const investmentsStore = useInvestmentsStore()
    const shares = investmentsStore.getSharesList

    const sharesQueries = shares.map(share =>
      client.query<CountImportedCashEventRowsQuery, CountImportedCashEventRowsQueryVariables>(
        CountImportedCashEventRowsDocument,
        { investorId, shareId: share.id },
        { clientName: 'airdistrib' }
      ).toPromise()
    )

    const sharesResults = await Promise.all(sharesQueries)
    const sharesWithImportedCashEventRows = shares.filter(share => (sharesResults.find(result => result.operation.variables.shareId == share.id).data?.countCashEventRows ?? 0) > 0)
    const sharesWithCalculatedCashEventRows = shares.filter(share => !sharesWithImportedCashEventRows.some(s => s?.id === share.id))

    // NOTE: Just for testing if we don't have the real data
    // const sharesWithImportedCashEventRows = shares.slice(0, Math.floor(shares.length / 2))
    // const sharesWithCalculatedCashEventRows = shares.slice(Math.floor(shares.length / 2))

    if (sharesWithImportedCashEventRows.length > 0) {
      await AirfundRepositoryAdapter.fillImportedTransactions({
        client,
        investorId,
        categories,
        sharesToFill: sharesWithImportedCashEventRows
      })
    }

    if (sharesWithCalculatedCashEventRows.length > 0) {
      await AirfundRepositoryAdapter.fillCalculatedTransactions({
        client,
        investorId,
        categories,
        sharesToFill: sharesWithCalculatedCashEventRows
      })
    }
    return categories
  }

  public static async fillCalculatedTransactions ({ client, investorId, categories, sharesToFill }: { client: Client, investorId: string, categories: InvestmentsState['categories'], sharesToFill: ShareItem[] }): Promise<InvestmentsState['categories']> {
    if (!sharesToFill) return categories

    const companyStore = useCompanyStore()
    const tradesQuery = await client.query<GetTradesQuery, GetTradesQueryVariables>(
      GetTradesDocument,
      { investorId },
      { clientName: 'airdistrib' }
    ).toPromise()

    const tradesResults = tradesQuery?.data
    const allTrades = [...tradesResults!.subscriptions, ...tradesResults!.redemptions, ...tradesResults!.transfersIn, ...tradesResults!.transfersOut]

    const createEventRowElement = (cashEventRow: any, date: number, share: any, currencySymbol: string, trade?: any) => {
      if (!cashEventRow) return null
      const isCashEventCall = (() => {
        switch (cashEventRow?.cashEventType) {
          case CashEventType.Call:
          case CashEventType.ReturnOfCall:
            return true;
          case CashEventType.Equalization:
            return cashEventRow.cashEventRowType === CashEventRowType.CashEventRowTypeCall || cashEventRow.cashEventRowType === CashEventRowType.CashEventRowTypeReturnOfCall;
          default:
            return false;
        }
      })();
      let code;
      switch (cashEventRow?.cashEventType) {
        case CashEventType.ReturnOfCall:
          code = 'return_of_call';
          break;
        case CashEventType.Call:
          code = 'call';
          break;
        case CashEventType.ReCall:
          code = 'distribution_recall';
          break;
        case CashEventType.Equalization:
          switch (cashEventRow.cashEventRowType) {
            case CashEventRowType.CashEventRowTypeCall:
              code = 'call';
              break;
            case CashEventRowType.CashEventRowTypeDistribution:
              code = '_distribution';
              break;
            case CashEventRowType.CashEventRowTypeReCall:
              code = 'distribution_recall';
              break;
            case CashEventRowType.CashEventRowTypeReturnOfCall:
              code = 'return_of_call';
              break;
            default:
              code = 'call';
          }
          break;
        default:
          code = 'distribution';
      }
      let amount = cashEventRow?.amount
      const shouldNegateAmount =
        cashEventRow?.cashEventType === CashEventType.ReCall ||
        cashEventRow?.cashEventType === CashEventType.ReturnOfCall ||
        (cashEventRow?.cashEventType === CashEventType.Equalization &&
          (cashEventRow?.cashEventRowType === CashEventRowType.CashEventRowTypeReturnOfCall ||
           cashEventRow?.cashEventRowType === CashEventRowType.CashEventRowTypeReCall))

      if (shouldNegateAmount) {
        amount = cashEventRow?.amount * -1
      }
      let percentage: number | undefined | null;
      if (isCashEventCall) {
        percentage = cashEventRow?.cashEvent?.rate;
        if (!percentage) {
          try {
            percentage = Math.abs((amount / engaged) * 100);
          } catch {
            percentage = undefined;
          }
        }
      } else {
        try {
          const totalAmountCalled = share.indicators.find(indicator => indicator.code === 'amountCalled')?.value?.value;
          percentage = totalAmountCalled ? ((amount / totalAmountCalled) * 100) : null;
        } catch {
          percentage = null;
        }
      }

      let description = cashEventRow?.desc;
      if (cashEventRow?.adjustment) {
        description = i18n.t('trade_types.call_adjustment');
      }
      if (cashEventRow?.cashEventType === CashEventType.Equalization) {
        description = i18n.t('transactions.types.equalization');
      }
      if (trade && cashEventRow?.effectiveDate !== date) {
        if (trade.tradeType === TradeType.TransfertIn) {
          description = i18n.t('trade_types.transfer_in_catch_up', {
            date: convertUnixDate(cashEventRow?.effectiveDate!),
          })
        }
        if (trade.tradeType === TradeType.TransfertOut) {
          description = i18n.t('trade_types.transfer_out_catch_up', {
            date: convertUnixDate(cashEventRow?.effectiveDate!),
          })
        }
      }

      const baseElement = {
        amount: {
          value: cashEventRow?.amount,
          display: formatCurrency(amount, currencySymbol, { decimals: 2, fillDecimals: true }),
        },
        date: {
          value: convertUnixDate(date!, DateFormat.YYYYMMDD),
          display: convertUnixDate(date!)
        },
        code,
        description,
      };

      if (isCashEventCall) {
        return {
          ...baseElement,
          pct: {
            value: percentage,
            display: formatNumber(percentage, { append: '%', decimals: 2, fillDecimals: true }),
          },
        };
      } else {
        return {
          ...baseElement,
          share: share.name,
          amountPercentage: {
            value: percentage,
            display: formatNumber(percentage, { append: '%', decimals: 2, fillDecimals: true })
          },
          shareAmount: {
            value: cashEventRow?.numberOfShares,
            display: formatNumber(cashEventRow?.numberOfShares!)
          },
        };
      }
    };

    const detailedTransferts = companyStore.settings.TRANSFER_CASHEVENTS_DETAILED

    categories.forEach(category => {
      category?.umbrellas?.forEach(umbrella => {
        umbrella.funds.forEach(fund => {
          const shares = fund.shares.filter(share => sharesToFill.map(s => s.id).includes(share.id))
          shares.forEach(async share => {
            const shareTrades = allTrades.filter(trade => trade.airFundKeys?.shareClass === share.id)
            const transactionsAndRedemptions = shareTrades.filter(trade =>
              [TradeType.TransfertIn, TradeType.TransfertOut, TradeType.Redemption].includes(trade.tradeType as TradeType)
            )
            let callsCashEvent: ShareItem['transactions']['calls'] = []
            let distributionsCashEvent: ShareItem['transactions']['distributions'] = []

            const shareTradesWithoutTransfer = shareTrades.filter(trade => trade.tradeType !== TradeType.TransfertIn && trade.tradeType !== TradeType.TransfertOut)
            const shareTradesWithTransfer = shareTrades.filter(trade => trade.tradeType === TradeType.TransfertIn || trade.tradeType === TradeType.TransfertOut)

            const shareTradesToAggregate = detailedTransferts ? shareTradesWithoutTransfer : shareTrades
            const sharesTradesToNotAggregate = detailedTransferts ? shareTradesWithTransfer : []


            let tradesByDate: any[] = []
            shareTradesToAggregate.forEach(trade => {
              const entry = tradesByDate.find(item => item[0] === trade.valueDate);
              if (!entry) {
                tradesByDate.push([trade.valueDate, [trade]]);
              } else {
                entry[1].push(trade);
              }
            });


            // // RATTRAPAGE
            await Promise.all(shareTrades.map(async (trade) => {
              let catchingUpCashEvents: any[] = []
              const tradeDate = trade?.valueDate!
              if (detailedTransferts && (trade.tradeType === TradeType.TransfertIn || trade.tradeType === TradeType.TransfertOut)) {
                trade.cashEventRows?.forEach(row => {
                  catchingUpCashEvents.push({ cashEventRow: row })
                })
              } else {
                const catchingUpCashEventQuery = await client.query<GetCatchingUpCashEventRowsQuery, GetCatchingUpCashEventRowsQueryVariables>(
                  GetCatchingUpCashEventRowsDocument,
                  {
                    investorId,
                    shareClassId: share.id,
                    tradeIds: [trade.id],
                    startDate: 0,
                    endDate: tradeDate - 1 || 0,
                  },
                  { clientName: 'airdistrib' }
                )
                catchingUpCashEvents = catchingUpCashEventQuery?.data?.aggregatedCashEventRows ?? []
              }
              const currencySymbol = getCurrencySymbol(share.currency)
              const engaged = share?.transactions?.totalSubscription?.engagement?.value || 0
              catchingUpCashEvents.forEach(cashEvent => {
                const date = Math.max(cashEvent?.cashEventRow?.effectiveDate!, trade?.valueDate!) || cashEvent?.cashEventRow?.effectiveDate!
                const eventRowElement = createEventRowElement(cashEvent.cashEventRow, date, share, currencySymbol, trade);


                if (cashEvent.cashEventRow?.cashEventType === CashEventType.Call || cashEvent.cashEventRow?.cashEventType === CashEventType.ReturnOfCall || cashEvent.cashEventRow?.cashEventType === CashEventType.Equalization) {
                  if (trade.tradeType === TradeType.TransfertIn || trade.tradeType === TradeType.TransfertOut) {
                    callsCashEvent.push({...eventRowElement} as CallItem);
                  } else {
                    callsCashEvent.push(eventRowElement as CallItem);
                  }
                } else {
                  if (trade.tradeType === TradeType.TransfertIn || trade.tradeType === TradeType.TransfertOut) {
                    distributionsCashEvent.push({...eventRowElement} as DistributionItem);
                  } else {
                    distributionsCashEvent.push(eventRowElement as DistributionItem);
                  }
                }
              })
            }))

            // AGGREGATIONS
            await Promise.all(tradesByDate.map(async(tradeByDate, tIndex) => {
              const tradeDate = tradeByDate[0]
              const todayUnixDate = Math.floor(Date.now()/86400000)
              const nextTradeDate = tIndex + 1 === tradesByDate.length ? todayUnixDate : tradesByDate[tIndex + 1][0]!
              // On prends tous les trades ayant déjà eu lieu ou à la date du trade actuel
              const tradeIds = tradesByDate.filter(date => date[0] <= tradeDate).map(item => item[1]).flat().map(item => item.id)

              const aggregatedsCashEventsQuery = await client.query<GetAggregatedCashEventRowsQuery, GetAggregatedCashEventRowsQueryVariables>(
                GetAggregatedCashEventRowsDocument,
                {
                  investorId,
                  shareClassId: share.id,
                  tradeIds: tradeIds,
                  startDate: tradeDate,
                  endDate: nextTradeDate - 1
                },
                { clientName: 'airdistrib'}
              )
              const aggregatedsCashEvents = aggregatedsCashEventsQuery?.data?.aggregatedCashEventRows ?? []

              const equalizationCashEventsQuery = await client.query<GetEqualizationCashEventRowsQuery, GetEqualizationCashEventRowsQueryVariables>(
                GetEqualizationCashEventRowsDocument,
                { investorId, shareClassId: share.id },
                { clientName: 'airdistrib' }
              )
              const equalizationCashEvents = equalizationCashEventsQuery?.data?.aggregatedCashEventRows ?? []
              const allCashEvents = [...aggregatedsCashEvents, ...equalizationCashEvents]

              const currencySymbol = getCurrencySymbol(share.currency)
              const engaged = share?.transactions?.totalSubscription?.engagement?.value || 0

              allCashEvents.forEach(cashEvent => {
                const date = Math.max(cashEvent?.cashEventRow?.effectiveDate!, tradeDate) || cashEvent?.cashEventRow?.effectiveDate!;
                const eventRowElement = createEventRowElement(cashEvent.cashEventRow, date, share, currencySymbol);
                if (!eventRowElement) return
                if (cashEvent.cashEventRow?.cashEventType === CashEventType.Call ||
                    cashEvent.cashEventRow?.cashEventType === CashEventType.ReturnOfCall ||
                    (cashEvent.cashEventRow?.cashEventType === CashEventType.Equalization &&
                     (cashEvent.cashEventRow?.cashEventRowType === CashEventRowType.CashEventRowTypeCall ||
                      cashEvent.cashEventRow?.cashEventRowType === CashEventRowType.CashEventRowTypeReturnOfCall))) {
                  callsCashEvent.push(eventRowElement);
                } else {
                  distributionsCashEvent.push(eventRowElement);
                }
              });
            }))
            const sortedCallsCashEvent = callsCashEvent.sort((a, b) => {
              return compareDesc(new Date(a.date.value), new Date(b.date.value))
            })
            const sortedDistributionsCashEvent = distributionsCashEvent.sort((a: any, b: any) => {
              return compareDesc(new Date(a.date.value), new Date(b.date.value))
            })
            share.transactions.redemptionsAndTransfers = afRedemptionsToDomain(transactionsAndRedemptions, share, companyStore.settings.PRIORITIZE_GROSS_AMOUNT_FOR_TRADES)
            share.transactions.calls = sortedCallsCashEvent
            share.transactions.distributions = sortedDistributionsCashEvent
          })
        })
      })
    })
    return categories
  }

  public static async fillImportedTransactions ({ client, investorId, categories, sharesToFill }: { client: Client, investorId: string, categories: InvestmentsState['categories'], sharesToFill: ShareItem[] }): Promise<InvestmentsState['categories']> {
    if (!sharesToFill) return categories
    const companyStore = useCompanyStore()
    const transactionsQuery = await client.query<GetTransactionsQuery, GetTransactionsQueryVariables>(
      GetTransactionsDocument,
      { investorId },
      { clientName: 'airdistrib' }
    ).toPromise()

    const transactions = transactionsQuery?.data
    const redemptionsArr = [...transactions!.redemptions, ...transactions!.transfersIn, ...transactions!.transfersOut]
    const calls = [...transactions!.calls, ...transactions!.returnOfCalls, ...transactions!.adjustmentCalls]
    const distributions = [...transactions!.distributions, ...transactions!.distributionRecalls]
    const aggregatedTransfersIn = transactions?.aggregatedTransfersIn ?? []

    categories.forEach(category => {
      category?.umbrellas?.forEach(umbrella => {
        umbrella.funds.forEach(fund => {
          fund.shares.filter(share => sharesToFill.map(s => s.id).includes(share.id)).forEach(share => {
            share.transactions.calls = afCallsToDomain(calls, aggregatedTransfersIn, share)
            share.transactions.distributions = afDistributionsToDomain(distributions, aggregatedTransfersIn, share)
            share.transactions.redemptionsAndTransfers = afRedemptionsToDomain(redemptionsArr, share, companyStore.settings.PRIORITIZE_GROSS_AMOUNT_FOR_TRADES)
          })
        })
      })
    })

    return categories
  }

  // TODO: connecter le repository aux vraies APIs
  public static async getPositionsData (categoryCode = 'ALL') {
    throw new Error('not implemented')
  }

  public static async getUserDocumentsData ({ client, investorId }: { client: Client, investorId: string }): Promise<{ user: DocumentsState['user'] }> {
    const userDocumentsQuery = await client.query<GetInvestorDocumentsFromAwQuery>(
      GetInvestorDocumentsFromAwDocument,
      { id: investorId },
      { clientName: 'airwealth' }
    ).toPromise()

    return {
      user: userDocumentsToDomain(userDocumentsQuery.data?.me?.investorByAfId)
    }
  }

  public static async getInvestmentsDocumentsData ({ client, investorId, funds }: { client: Client, investorId: string, funds: FundItem[] }): Promise<DocumentsState['investments']['documents']> {
    const shareIds = funds.map(fund => fund.shares.map(share => share.id)).flat()

    if (shareIds.length > 0) {
      const sharesDocumentsAFQuery = await client.query<GetDocumentsFromAfQuery, GetDocumentsFromAfQueryVariables>(
        GetDocumentsFromAfDocument,
        { shareIds: shareIds, investorId },
        { clientName: 'airfund' }
      ).toPromise();

      const shareClasses = sharesDocumentsAFQuery?.data?.shareClasses ?? []
      const investor = sharesDocumentsAFQuery?.data?.investor
      let documents: DocumentsState['investments']['documents'] = []

      shareClasses.forEach(shareClass => {
        const docs = shareDocumentsToDomain(shareClass) ?? []
        documents.push(...docs)
      })

      if (investor) {
        const docs = investorDocumentsToDomain(investor, funds) ?? []
        documents.push(...docs)
      }

      // remove duplicates documents
      documents = documents.filter((doc, index, self) => self.findIndex(d => d.id === doc.id) === index)

      return documents.sort((a, b) => {
        return compareDesc(new Date(a.date.value), new Date(b.date.value))
      })
    }

    return []
  }

  public static async getNoticesDocumentsData ({ client, investorId, funds }: { client: Client, investorId: string, funds: FundItem[] }): Promise<DocumentsState['investments']['notices']> {
    const shareIds = funds.map(fund => fund.shares.map(share => share.id)).flat()
    if (shareIds.length > 0) {
      const cashEventWorkflows = await client.query<GetCashEventWorkflowsByShareClassesQuery, GetCashEventWorkflowsByShareClassesQueryVariables>(
        GetCashEventWorkflowsByShareClassesDocument,
        { shareIds: shareIds, investorIds: [investorId] },
        { clientName: 'airdistrib' }
      ).toPromise();

      const notices = cashEventWorkflows?.data?.cashEventWorkflows ?? []
      const documents = noticesDocumentsToDomain(notices, funds) ?? []
      return documents.sort((a, b) => {
        return compareDesc(new Date(a.date.value), new Date(b.date.value))
      })
    }

    return []
  }

  public static async updateUserDocument ({ client, file, document }: { client: Client, file: File, document: UserDocument }): Promise<{ user: DocumentsState['user'] } | null> {
    const { ressourceId, investorId, id, type } = document

    removeAirwealthDocumentFromS3(ressourceId) // delete old doc from s3
    const { hash } = await uploadAirwealthDocumentToS3(file) as { hash: string, url: string } // upload new doc to s3

    if (hash) {
      await Promise.all([
        // remove old document from investor
        client.mutation<InvestorRemoveDocMutation, InvestorRemoveDocMutationVariables>(
          InvestorRemoveDocDocument,
          { documentId: id as string, investorId: investorId as string },
          { clientName: 'airwealth' }
        ).toPromise(),
        // add new document to investor
        client.mutation<InvestorAddDocMutation, InvestorAddDocMutationVariables>(
          InvestorAddDocDocument,
          { input: { hash, name: file.name, type }, investorId: investorId as string },
          { clientName: 'airwealth' }
        ).toPromise()
      ])

      const userDocumentsQuery = await client.query<GetInvestorDocumentsFromAwQuery>(
        GetInvestorDocumentsFromAwDocument,
        { id: investorId },
        { clientName: 'airwealth' }
      ).toPromise()

      return {
        user: userDocumentsToDomain(userDocumentsQuery.data?.me?.investorByAfId)
      }
    }

    return null
  }

  public static async updateTaskStatus ({ client, notificationId }: { client: Client, notificationId: string }): Promise<boolean> {
    const result = await client.mutation<UpdateTaskStatusMutation, UpdateTaskStatusMutationVariables>(
      UpdateTaskStatusDocument,
      { id: notificationId },
      { clientName: 'airfund' }
    ).toPromise()

    return result?.data?.task?.update?.status === TaskStatus.Done
  }

  public static async archiveNotification ({ client, notificationId }: { client: Client, notificationId: string }): Promise<Notification | null> {
    const result = await client.mutation<ArchiveNotificationMutation, ArchiveNotificationMutationVariables>(
      ArchiveNotificationDocument,
      { notificationId: notificationId },
      { clientName: 'airfund' }
    ).toPromise()

    if (result.data?.archiveNotification) {
      return notificationsToDomain([result.data?.archiveNotification])?.[0]
    }
    return null
  }

  public static async updateProfilePicture ({ client, file }: { client: Client, file: File }): Promise<string | null> {
    try {
      const { id, url } = await uploadAirfundImageToS3(file) as { id: string, url: string }

      await client.mutation<UpdateProfileMutation, UpdateProfileMutationVariables>(
        UpdateProfileDocument,
        { input: { picture: id } },
        { clientName: 'airfund' }
      ).toPromise()

      return url || '/profile-fallback.png'
    } catch {
      return null
    }
  }

  public static async getProductsData({ client, filters, company }: { client: Client, filters: ProductsState['selectedFilters'], company: CompanyState }): Promise<Pick<ProductsState, 'products' | 'filters'>> {
    const user = company.user

    const params: Record<string, any> = {
      clientId: company.id
    }
    if (user.nationality) {
      params['passportings'] = [user.nationality]
    }
    if (user.investorType) {
      params['targetInvestorTypes'] = [user.investorType]
    }
    if (user.usPerson !== null) {
      params['usPerson'] = user.usPerson
    }

    const recommandedFundsQuery = await client.query<GetRecommendedFundsFromAfQuery, GetRecommendedFundsFromAfQueryVariables>(
      GetRecommendedFundsFromAfDocument,
      params,
      { clientName: 'airfund' }
    ).toPromise()

    let recommandedFunds: string[] = []
    recommandedFundsQuery?.data?.selectionLists?.forEach(list => {
      list.shareClasses.forEach(share => {
        const id = share.shareClass?.fund?.id
        if (id) {
          recommandedFunds.push(id)
        }
      })
    })
    recommandedFunds = [...new Set(recommandedFunds.filter(item => !!item))]

    let funds: GetProductsFromAfQuery['funds'] = []
    if (recommandedFunds.length > 0) {
      const productsQuery = await client.query<GetProductsFromAfQuery, GetProductsFromAfQueryVariables>(
        GetProductsFromAfDocument,
        {
          fundIds: recommandedFunds,
          assetClasses: filters.assetClasses.map(item => item.value) as AssetClass[],
          investmentStrategies: filters.strategies.map(item => item.value) as InvestmentStrategy[],
          sfdrs: filters.sfdrs.map(item => item.value) as Sfdr[]
        },
        { clientName: 'airfund' }
      ).toPromise()
      funds = productsQuery.data?.funds || []
    }

    const products = funds.map(fund => {
      fund.indicators.sort((a, b) => b.date - a.date)
      const indicators = [
        targetMultipleToIndicator(fund.indicators),
        targetTriToIndicator(fund.indicators)
      ]
      const subscriptions = fund.shareClasses.map(share => {
        return share?.executionData?.minimalInitialSubscriptionInAmount
      })
      const minimalInitialSubscription = Math.min(...(subscriptions.filter(Boolean) as number[]))

      const caracteristics = fundCaracteristicsToDomain(fund)
      caracteristics.push({
        code: 'minimal_initial_subscription',
        value: minimalInitialSubscription ? formatCurrency(minimalInitialSubscription, '€') : '-'
      })

      return {
        id: fund.id,
        name: fund.legalFundNameOnly,
        description: fund.shortDescription,
        managementObjectives: null,
        image: getPublicUrlFromS3(fund?.picture as string),
        indicators,
        caracteristics,
        shares: [],
        documents: [],
        labels: fund.labels
      }
    }) as unknown as ProductsState['products']

    let assetClasses: string[] = []
    let strategies: string[] = []
    let sfdrs: string[] = []

    funds.forEach(fund => {
      const assetClass = fund?.privateEquity?.assetClass
      assetClasses.push(assetClass as string)

      const investmentStrategies = fund?.privateEquity?.investmentStrategies || []
      strategies.push(...investmentStrategies)

      sfdrs.push(fund?.sfdr as string)
    })

    // remove duplicates and empty values
    assetClasses = [...new Set(assetClasses.filter(item => !!item))]
    strategies = [...new Set(strategies.filter(item => !!item))]
    sfdrs = [...new Set(sfdrs.filter(item => !!item))]

    return {
      products,
      filters: {
        assetClasses: assetClasses.map(item => {
          return {
            value: item,
            name: i18n.t(`products.asset_classes.${item.toLowerCase()}`)
          }
        }),
        strategies: strategies.map(item => {
          return {
            value: item,
            name: i18n.t(`products.investment_strategies.${item.toLowerCase()}`)
          }
        }),
        sfdrs: sfdrs.map(item => {
          return {
            value: item,
            name: i18n.t(`products.sfdrs.${item.toLowerCase()}`)
          }
        })
      }
    }
  }

  public static async getProductData ({ client, productId }: { client: Client, productId: string }): Promise<ProductsState['products'][0]> {
    const productQuery = await client.query<GetProductFromAfQuery, GetProductFromAfQueryVariables>(
      GetProductFromAfDocument,
      { id: productId },
      { clientName: 'airfund' }
    ).toPromise();

    const fund = productQuery?.data?.fund
    const shares = fund?.shareClasses ?? []
    let documents = fund?.documents ?? []
    if (shares.length > 0) {
      shares.forEach(share => {
        documents = [...documents, ...share.documents]
      })
    }
    documents = documents.filter(doc => doc?.partnersOnly !== true)
    // remove duplicates documents
    documents = documents.filter((doc, index, self) => self.findIndex(d => d.id === doc.id) === index)

    return {
      id: productId,
      indicators: [],
      caracteristics: fundCaracteristicsToDomain(fund),
      name: fund?.legalFundNameOnly,
      image: getPublicUrlFromS3(fund?.picture as string),
      description: fund?.shortDescription,
      managementObjectives: fund?.description[0]?.content,
      shares: shares.map(share => {
        return {
          extension: share.shareClassExtension,
          isin: share.isin,
          caracteristics: shareCaracteristicsToDomain(share)
        }
      }),
      documents: documents.map(doc => {
        return {
          title: doc.name,
          date: convertEpochDayToDate(doc?.date as number),
          type: doc?.documentType,
          file: doc?.resource?.fileName,
          ressourceId: doc?.resource?.id
        }
      }),
      labels: fund?.labels
    } as ProductsState['products'][number]
  }

  public static async createLead ({ client, formInputs, companyId, fundId }: { client: Client, formInputs: LeadInputs, companyId: string, fundId: string }): Promise<boolean> {
    try {
      const result = await client.mutation<CreateLeadMutation, CreateLeadMutationVariables>(
        CreateLeadDocument,
        {
          recipientCompanyId: companyId,
          investmentMessage: formInputs.message,
          investmentAmount: formInputs.amount,
          investmentTarget: formInputs.duration,
          funds: [{ _id: fundId }]
        },
        { clientName: 'airfund'}
      ).toPromise()

      return result?.data?.lead.create._id !== null
    } catch {
      return false
    }
  }

  public static async getSettingsData ({ client, companyId }: { client: Client, companyId: string }): Promise<CompanyState['settings']> {
    const settingsRequest = await client.query<GetInvestorPortalSettingsQuery, GetInvestorPortalSettingsQueryVariables>(
      GetInvestorPortalSettingsDocument,
      { clientId: companyId },
      { clientName: 'airfund' }
    ).toPromise()

    return settingsRequest.data?.settings?.deploy || {}
  }

  public static async callReadToNotice ({ client, noticeId, funds }: { client: Client, noticeId: string, funds: FundItem[] }): Promise<NoticeDocument> {
    const result = await client.mutation<ReceiveCashEventWorkflowMutation, ReceiveCashEventWorkflowMutationVariables>(
      ReceiveCashEventWorkflowDocument,
      { workflowId: noticeId },
      { clientName: 'airdistrib' }
    ).toPromise()
    const workflow = result?.data?.receiveCashEventWorkflows?.cashEventWorkflows?.[0].cashEventWorkflow
    return noticesDocumentsToDomain([workflow], funds)?.[0]
  }

  // NOTE: subscriptions are not working yet
  public static useNotificationsSubscription(client: Client, handleData: (data: any) => void, handleError: (error: Error) => void) {
    const { unsubscribe } = client.subscription(SubscribeNotificationsDocument, {}, { clientName: 'airfund-ws' }).subscribe(result => {
      if (!result.error) {
        handleData(result.data);
      } else {
        handleError(result.error);
      }
    });
    return unsubscribe
  }
}
